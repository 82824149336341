.check-outro {
    &-body {
        @media only screen and (min-width : 1020px) {
            display: grid;
        }
        width: 100%;
        min-height: 600px;
        &-container {
            &-details {
                display: flex;
                &-row {
                    width: 100%;
                    &-content {
                    }
                    .act-btns {
                        @media only screen and (max-width : 420px) {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }

    }
}