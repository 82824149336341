#dvla-comp-wrapper {
    .compliance-container {
        margin: 20px 0px 20px 0px;
        display: flex;
        .stepper-content {
            padding: 10px;
            padding-top: 40px;
            padding-left: 80px;
            background-color: #FAFAFA;
            .step-label {
                word-wrap: break-word;
                font-family: Montserrat-SemiBold;
                width: 100%;
                font-size: 20px;
                font-weight: 200;
                color: #343C44;
                .step-last {
                    margin: 10px 10px 10px 10px;
                }
                .step-label-desc {
                    font-family: Lato-Regular;
                    font-size: 16px;
                    word-wrap: break-word;
                    width: 100%;
                    margin: 10px 10px 10px 10px;
                }
            }
        }
        .container-content {
            width: 100%;
            display: flex;
            .container-content-form {
                width: 100%;
            }
        }
    }
    
}