#dbs-comp-wrapper {
    width: 100%;
    .compliance-container {
        margin: 20px 0px 20px 0px;
        display: flex;
        .stepper-content {
            padding: 10px;
            padding-top: 40px;
            padding-left: 40px;
            background-color: #FAFAFA;
            .step-label {
                word-wrap: break-word;
                font-family: Montserrat-SemiBold;
                width: 100%;
                font-size: 20px;
                font-weight: 200;
                color: #343C44;
                .step-last {
                    margin: 10px 10px 10px 10px;
                }
                .step-label-desc {
                    font-family: Lato-Regular;
                    font-size: 16px;
                    word-wrap: break-word;
                    width: 100%;
                    margin: 10px 10px 10px 10px;
                }
            }
        }
        .container-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            .complaince-change-request {
                background: rgba(215, 77, 38, 0.2);
                border-radius: 5px;
                .changes-requested-text {
                    font-family: Lato-Regular;
                    font-weight: 400;
                    font-size: 16px;
                    text-align: center;
                    line-height: 16px;
                    color: #D74D26;
                    padding: 7px;
                }
            }
            .container-content-dtls {
                display: flex;
                position: relative;
                .container-content-form {
                    width: 100%;
                }
            }
        }
    }
}

.confirm-import-modal {
    display: flex;
    flex-direction: column;
    margin: 10px 15px 10px 15px;
    max-height: 80%;

    .data-fields {
        display: flex;
        .data-field-title {
            font-family: Montserrat-Bold;
            font-size: 16px;
            border-bottom: 1px solid #343C44;
        }
        .data-field-sub-title {
            font-family: Montserrat-Semibold;
            font-size: 14px;
            margin: 0px;
        }
        .data-show-label {
            color: #757575;
            font-family: Montserrat-Regular;
            font-size: 16px;
            font-weight: 200;
            display: flex;
            .data-show-value {
                font-family: Lato-Bold;
                color: #343C44;
                font-size: 16px;
                font-weight: 100;
                line-height: 20px;
                word-wrap: break-word;
            }
        }
    }
}
.import-actions {
    padding-top: 30px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0;
}