.file-input {
    display: none;
}

.file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: #4aa1f3;
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
}

.upload-icon-name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #343C44;
    text-align: center;
    cursor: pointer;
}

.upload-icon-name-broser {
    color: #ff5300;
}

.upload-icon-second {
    color: rgb(173, 184, 191);
    font-family: Montserrat-Medium;
    font-size: 12px;
    font-weight: 500;
    padding: 20px;
}

.container {
    height: 100%;
    :hover {
        background-color: #E9F1FD;
        border-radius: 15px;
    }
    p {
        text-align: center;
        overflow: auto;
        vertical-align: middle;
    }
}

.drop-container {
    min-height: 160px;
    height: 100%;
    top: 50%;
    transition: transform(-50%);
    background: #FFFFFF;
    border: 2px dashed #2D7FF9;
    border-radius: 15px;
}

.upload-icon {
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    padding-top: 30px;
    svg {
        width: 40px;
        height: 40px;
    }
}

.drop-message {
    text-align: center;
    color: #4aa1f3;
    font-family: Montserrat-Medium;
    vertical-align: middle;
    height: 100%;
    font-size: 20px;
}
.file-progressBar div {
    height: auto;
    color: #fff;
    text-align: right;
    line-height: 15px;
    width: 0;
    background-color: #4caf50;
    border-radius: 3px;
    font-size: 13px;
}

.file-display-container {
    overflow: hidden;
    min-height: 200px;
    border-radius: 4px;
    margin: 20px 0px 20px 0px;
}

.file-status-bar {
    width: 100%;
    vertical-align: top;
    position: relative;
    line-height: 50px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid rgb(221, 224, 233);
    display: flex;
    justify-content: space-between;
}

.file-status-bar > div {
    overflow: hidden;
}

.file-type {
    display: inline-block !important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.file-name {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #042D76;
}

.file-error {
    vertical-align: top;
    text-align: center;
    font-family: Montserrat-Medium;
    font-size: 16px;
}

.file-error-message {
    color: red;
}

.file-type-logo {
    width: 50px;
    height: 50px;

    background-size: 100%;
    position: absolute;
}

.file-size {
    display: inline-block;
    vertical-align: top;
    color: #30693d;
    margin-left: 10px;
    margin-right: 5px;
    font-weight: 700;
    font-size: 14px;
}

.file-remove {
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-top: 10px;
    margin-right: 10px;
}

.modal {
    z-index: 999;
    display: none;
    overflow: hidden;
}

.modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
}

.modal .modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.modal-image-text {
    position: absolute;
    color: red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.upload-modal {
    z-index: 999;
    display: none;
    overflow: hidden;
}

.upload-modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
}

.progress-container {
    background: white;
    width: 500px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.progress-container span {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
}

.progress {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;
}

.progress-bar {
    position: absolute;
    background-color: #4aa1f3;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.error {
    color: red;
}