.dbs-terms-conditions {
  &-body {
    width: 100%;
    &-container {
      display: flex;
      flex-direction: column;
      &-details {
        display: flex;
        &-rows {
          display: flex;
          align-items: center;
          flex-direction: column;
          max-height: 60vh;
          overflow-y: scroll;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            width: 8px;
            margin-left: 3px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px transparent;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(21, 53, 73, 0.2);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: rgba(21, 53, 73, 0.2);
          }
          &-row {
            width: 100%;
            &-content {
              margin-top: 1.5em;
              margin-bottom: 1.5em;
            }
            .check-box-ctrls {
              margin: 15px 15px 15px 0px;
              float: left;
            }
          }
        }
      }
    }
  }
  .labels {
    margin-top: 1.5em;
  }
  .tnc-question-text {
    font-family: Montserrat-Bold;
    font-weight: 400;
    padding: 20px 0px 20px 0px;
  }
  .MuiTypography-body1 {
    max-width: 100%;
  }
}