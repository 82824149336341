.check-intro {
    width: 100%;
    &-body {
        width: 100%;
        min-height: 600px;
        &-container {
            &-details {
                display: flex;
                &-row {
                    display: flex;
                    flex-direction: column;
                    &-content {
                        margin-top: 0px;
                    }
                }
            }
        }

    }
}