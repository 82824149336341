.MuiAccordion-root {
    background-color: transparent !important;
    border-radius: 0;
    box-shadow: none !important;
}
#accordion-wrapper {
    .heading-wrapper .text {
        border: none !important;
        margin-bottom: 0 !important;
        color: #2d7ff9 !important;
        padding-right: 20px !important;
        font-size: 18px !important;
    }

    .heading-title {
        display: flex;
        align-items: center;
        flex-grow: 1;
        hr {
            display: flex;
            border-top: 1px solid #2d7ff9;
            border-bottom: none;
            flex-grow: 1;
            height: 1px;
        }
    }
}
