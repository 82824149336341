.alert-modal-container {
    overflow: hidden;
    .modal-inner {
      filter: blur(0px);
      background: rgb(255, 255, 255);
      border: 1px solid rgb(235, 237, 244);
      box-shadow: 8px 8px 16px 0px rgba(74, 80, 82, 0.4);
      border-radius: 25px!important;
      height: 341px;
      width: 480px;
      margin: 80px auto;
      outline: none;
      padding: 30px 10px;
      .image-container {
        display: flex;
        justify-content: center;
        .icon {
          height: 35px;
          width: 35px;
          margin: 0px auto;
        }
      }
      .error-header {
        color: #d10000 !important;
      }
      .section-header {
        color: rgb(49, 49, 49);
        font-family: Gilroy-Semibold, sans-serif;
        font-size: 21px;
        font-weight: 600;
        height: 21px;
        letter-spacing: 0px;
        text-align: center;
        margin: 10px auto;
      }
      .modal-content {
        padding: 20px 10px 20px 10px;
        width: auto;
        justify-content: center;
        text-align: center;
        max-height: 200px;
        overflow-y: auto;
        .modal-card {
          width: auto;
        }
        .modal-input {
            font-family: Lato-Regular;
            font-size: 18px;
            font-weight: 100;
            line-height: 30px;
            color: #343C44;
        }
      }
      .button-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: initial;
        padding: 20px 0px 20px 0px;
        .primary-disabled {
            color: #FBFBFB !important;
            background: #E0E0E0 !important;
            border: 2px solid #E0E0E0;
            border-radius: 25px;
            cursor: inherit !important;
            &:hover {
            box-shadow: 0px 1px 6px 5px rgba(224, 224, 224, 0.3);
            }
        }
        .primary {
            height: 50px;
            width: 200px;
            font-size: 20px;
            font-family: Lato-Regular;
            font-weight: 400;
            text-align: center;
            letter-spacing: 0px;
            border-radius: 25px;
            outline: none;
            border: 1px solid #2D7FF9;
            background-color: #2D7FF9;
            color: #FFFFFF;
            cursor: pointer;
            &:focus {
              outline: none;
            }
            &:hover {
              box-shadow: 0px 1px 6px 5px rgba(45, 127, 249, 0.3);
            }
        }
        .secondary {
            margin-left: 24px;
        }
      }
    }
  }
  