.heading-wrapper {
    text-align: left;
    display: grid;
    .text {
      padding: 20px 0px 20px 0px;
      margin-bottom: 40px;
      font-family: Montserrat-Bold;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #343c44;
      float: left;
      border-bottom: 1px solid #343C44;
      display: block;
      width: 100%;
    }
    .with-border {
      border-bottom: 1px solid #343C44;
    }
    .center-text {
      text-align: -webkit-center !important;
    }
    .float-left {
      float: left;
    }
    .sub-text {
      height: 16px;
      color: rgb(49, 49, 49);
      font-size: 16px;
      font-family: Montserrat-Medium;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 20px;
      height: 100%;
      letter-spacing: 0px;
    }
  }
  