.verify-identity {
    &-body {
        width: 100%;
        &-container {
            display: flex;
            flex-direction: column;
            &-details {
                display: flex;
                &-row {
                    &-content {
                        margin-top: 0px;
                        .MuiFormControl-root {
                            width: 100%;
                        }
                        .MuiFormGroup-row {
                            flex-direction: column;
                        }
                        .docGrpControl {
                            width: 75%;
                            margin: 50px 0px 0px 0px;
                        }
                        .docGroupLabel {
                            width: 25%;
                        }
                        .doc-value-fields {
                            display: flex;
                            @media only screen and (max-width : 420px) {
                                flex-direction: column;
                            }
                            padding: 0px 0px 20px 0px;
                            .generic-data-show {
                                margin-left: 10px;
                                margin-top: 20px;
                            }
                            .uploader-win {
                                margin-left: 10px;
                                .file-list-container {
                                    overflow: hidden;
                                    border-radius: 4px;
                                    margin: 20px 0px 20px 0px;
                                }
                            }
                            .MuiFormControl-root {
                                margin: 10px 10px 10px 10px !important;
                            }
                            .MuiAutocomplete-root {
                                margin: 0px 0px 0px 0px !important;
                            }
                        }
                    }
                }
            }
        }

    }
}

.yoti-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.digital-id-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .digital-id-message {
        font-family: Lato-Bold;
        color: #2D7FF9;
        font-size: 16px;
        font-weight: 100;
        min-height: 26px;
        margin: 10px 0 10px;
        word-wrap: break-word;
        width: 100%;
        padding: 10px 30px 10px 30px;
    }
    .digital-id-btn {
        width: 100%;
    }
}


.modal-content-height {
    max-height: 250px !important;
}

.personal-info {
    &-body {
        width: 100%;
        &-container {
            &-details {
                padding: 0px 0px 10px 0px;
                &-row {
                    width: 100%;
                    display: flex;
                    @media only screen and (max-width : 420px) {
                        flex-direction: column;
                    }
                    .question-field {
                        border: 2px solid #757575;
                        padding: 15px;
                        margin: 40px -30px 40px 0px;
                        border-radius: 18px;
                        .check-box-ctrls {
                          margin: 15px 20px 15px 20px;
                        }
                        .MuiFormGroup-root {
                            display: inherit;
                        }
                    }
                    .MuiFormControlLabel-label {
                        max-width: 100%;
                        width: 100%;
                    }
                    .p-t-30 {
                        padding-top: 30px;
                    }
                    .add-middleName {
                        width: 5%;
                        margin-left: 3px;
                        margin-top: 20px;
                        cursor: pointer;
                        float: right;
                    }
                }
                &-row-submit {
                    width: 100%;
                    .p-t-32 {
                        padding-top: 32px;
                    }
                }
                .empty-row {
                    padding-top: 40px;
                }
            }
            .empty-row {
                padding-top: 40px;
            }
        }

    }
    .error{
        color: #D74D26;
    }
}

.fullWidth {
    width: 100%;
    margin: 20px 30px 0px 0px;
}

.personal-info-edit-modal {
    //display: flex;
    justify-content: center; 
    width: 100%;   
}