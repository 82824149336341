.address-history {
  &-body {
    width: 100%;
    &-container {
      &-details {
        &-row {
          display: flex;
          width: 100%;
          @media only screen and (max-width: 420px) {
            flex-direction: column;
          }
          .p-t-32 {
            padding-top: 32px;
          }
          .question-field {
            border: 2px solid #757575;
            padding: 15px;
            margin: 40px -30px 40px 0px;
            border-radius: 18px;
            .check-box-ctrls {
              margin: 15px 20px 15px 20px;
            }
            .MuiFormGroup-root {
              display: inherit;
            }
          }
          .MuiFormControlLabel-label {
            max-width: 100%;
            width: 100%;
          }
        }
        .empty-row {
          padding-top: 40px;
        }
        &-row-submit {
          width: 100%;
          .p-t-32 {
            padding-top: 32px;
          }
        }
      }
    }
  }
  .error{
    color: #D74D26;
  }
}
