.generic-data-show {
    text-align: left;
    width: 100%;
    padding-top: 10px;
    &-label {
        color: #757575;
        font-family: Montserrat-Regular;
        font-size: 16px;
        font-weight: 200;
        min-height: 12px;
        width: 100%;
    }
    &-value {
        font-family: Lato-Bold;
        color: #343C44;
        font-size: 16px;
        font-weight: 100;
        min-height: 26px;
        width: 100%;
        margin: 10px 0 10px;
        word-wrap: break-word;
        .edit-icon {
            float: right;
            height: 24px;
            cursor: pointer;
            fill: rgba(45, 127, 249, 1) !important;
        }
        svg {
            height: 12px;
            fill: rgba(45, 127, 249, 1);
        }
        .display-none {
            display: none !important;
        }
    }
    &-underline {
        background: #757575;
        border-radius: 0px;
        height: 1px;
    }
}
