.MuiTypography-body1 {
    left: 15%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    max-width: 70%;
    margin-bottom: 15px;
    border-radius: 31.5px;
    font-family: Montserrat-Semibold !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 18px !important;
    display: flex !important;
}

.leftSidebar {
    margin-top: 70px;
}

.MuiGrid-root {
    padding-top: 5px;
}

.MuiList-root {
    a {
        text-decoration: none;
    }
    padding-top: 65px;
}
.MuiIconButton-label {
    color: #2D7FF9;
}

.logout-item {
    color: #757575;
}

.MuiButtonBase-root {
    padding-top: 65px;
    background-color: transparent !important;
}

.MuiListItem-root, .MuiSvgIcon-root {
    :hover {
        background-color: transparent !important;
        span, svg {
            color: #2D7FF9;
            fill: #2D7FF9;
        }
    }
}

.MuiList-root .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
    span {
        color: #2D7FF9;
    }
    svg {
        fill: #2D7FF9;
    }
    :hover {
        background-color: transparent !important;
    }
}
