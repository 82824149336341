#dashboard-wrapper {
  width: 100%;
  @media only screen and (min-width: 1020px) {
    padding-left: 58px;
    padding-top: 25px;
  }
  .title {
    font-family: Montserrat-bold;
    font-style: normal;
    font-size: 32px;
    line-height: 61px;
    color: black;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .subtitle {
    font-family: Montserrat-semibold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    padding: 30px 0px 20px 20px;
    color: black;
    max-width: 100%;
  }
  .status {
    margin-top: 10px;
    font-family: Montserrat-Semibold;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    padding-left: 20px;
    padding-top: 15px;
    color: #343c44;
    padding-left: 20px;
  }
  .info-title {
    font-family: Montserrat-semibold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    //padding: 30px 0px 20px 20px;
    color: black;
    max-width: 100%;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    .info-wrapper {
      display: flex;
      padding: 0 46px;
      .role-description {
        font-style: normal;
        .description {
          font-weight: normal;
          font-family: Montserrat-regular;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 5px;
        }
        .info {
          font-weight: bold;
          font-size: 20px;
          font-family: Montserrat-bold;
          line-height: 24px;
          margin-top: 0px !important;
          margin-bottom: 23px;
        }
      }
    }
    .role-wrapper {
      padding: 0px 20px 0px 20px;
      display: grid;
      background: rgba(45, 127, 249, 0.05);
      border-radius: 30px;
      width: 75%;
      padding: 45px;
    }
  }

  .text-with-bullet {
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;

    @media only screen and (max-width: 420px) {
      width: 70%;
    }
    &-p {
      margin: auto 0;
    }
    .label {
      width: 50%;
    }
    .value {
      width: 35%;
      text-align: end;
      color: #343c44;
      font-family: Montserrat-Bold;
    }
    .comp-point-icon {
      color: #2d7ff9;
      margin-right: 15px;
    }
  }
  .roles {
    mix-blend-mode: multiply;
    display: grid;
    margin-top: 20px;
    background: rgba(45, 127, 249, 0.05);
    border-radius: 30px;
    padding: 23px 0px;
    @media only screen and (min-width: 1020px) {
      width: 1118px;
    }
    &-header {
      display: flex;
      float: left;
      line-height: 35px;
      cursor: pointer;
      &-name {
        font-family: Montserrat-Bold;
        font-style: normal;
        font-weight: 700;
        overflow-x: inherit;
        width: 40%;
        max-width: 250px;
        font-size: 24px;
        justify-content: left;
        color: #343c44;
      }
      &-location {
        font-family: Montserrat-SemiBold;
        font-weight: bold;
        font-size: 20px;
        width: 40%;
        margin-left: 100px;
        justify-content: left;
        max-width: 250px;
        color: #343c44;
        svg {
          height: 20px;
          width: 20px;
          vertical-align: middle;
          margin-bottom: 6px;
        }
      }
      &-right {
        width: 25%;
        &-status {
          font-family: Montserrat-SemiBold;
          font-style: normal;
          font-weight: bold;
          float: right !important;
          font-size: 20px;
          align-items: flex-end;
          color: #93e088;
        }
      }
    }
    &-processes {
      display: grid;
      grid-template-columns: 332px 332px 332px;
      grid-column-gap: 15px;
      grid-row-gap: 20px;
      max-width: 80%;
      hr {
        width: 100%;
        @media only screen and (max-width: 420px) {
          width: 75%;
        }
        border-style: inset;
        border-top: 1px solid #000;
        border-bottom: none;
      }
      .comp-points {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 15px 18px;
        background-color: #ffffff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        @media only screen and (min-width: 1020px) {
          width: 332px;
        }
        @media only screen and (max-width: 420px) {
          width: 75%;
        }
        max-height: 235px;
        cursor: pointer;
        .comp-point-data {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          // padding-bottom: 10px;
          padding-bottom: 25px;
          .comp-point-icon {
            margin: -22px 0px 0px 20px;
            img,
            svg {
              fill: #2d7ff9;
              width: 35px;
              height: 35px;
            }
          }
          .comp-point-text {
            font-family: Montserrat-Bold;
            font-style: normal;
            font-weight: bold;
            max-width: 200px;
            font-size: 20px;
            color: #343c44;
          }
          .comp-point-text-2 {
            font-family: Lato-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin-right: 20px;
            color: #757575;
            text-align: end;
            flex-direction: row-reverse;
          }
        }
        .comp-point-status {
          align-items: flex-start;
          text-align: flex-start;
          display: flex;
          flex-direction: column;
          .line-wrapper {
            padding-bottom: 10px;
            display: flex;
            gap: 5px;
            justify-content: center;
          }
          .status-text {
            font-family: Montserrat-SemiBold;
            font-weight: bold;
            font-size: 14px;
          }
          .status-icon {
            height: 20px;
            width: 20px;
            margin-right: 15px;
          }
        }
      }
    }
  }
  .ProcessesCrs {
    padding: 10px;
    .Banner {
      position: relative;
      background: transparent !important;
      .BannerGrid {
        height: 100%;
        position: relative;
        justify-content: start;
        padding: 0 46px;
      }
    }
    .MuiIconButton {
      color: black !important;
    }
  }
}
