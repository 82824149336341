.verify-identity {
    &-body {
        width: 100%;
        &-container {
            display: flex;
            flex-direction: column;
            &-details {
                display: flex;
                &-row {
                    &-content {
                        margin-top: 0px;
                        .MuiFormControl-root {
                            width: 100%;
                        }
                        .MuiFormGroup-row {
                            flex-direction: column;
                        }
                        .docGrpControl {
                            width: 75%;
                            margin: 50px 0px 0px 0px;
                            .Mui-error.MuiFormHelperText-root.Mui-error{
                                color: rgb(209, 0, 0) !important;
                            }
                            .MuiFormHelperText-marginDense{
                                margin-top: 4px !important;
                            }
                            .MuiFormHelperText-root{
                                font-size: 0.75rem !important;
                            }
                            .MuiFormHelperText-contained {
                                margin-left: 14px !important;
                                margin-right: 14px !important;
                            }
                        }
                        .docGroupLabel {
                            width: 25%;
                        }
                        .doc-value-fields {
                            display: flex;
                            @media only screen and (max-width : 420px) {
                                flex-direction: column;
                            }
                            padding: 0px 0px 20px 0px;
                            .generic-data-show {
                                margin-left: 10px;
                                margin-top: 20px;
                            }
                            .uploader-win {
                                margin-left: 10px;
                                .file-list-container {
                                    overflow: hidden;
                                    border-radius: 4px;
                                    margin: 20px 0px 20px 0px;
                                }
                            }
                            .MuiFormControl-root {
                                margin: 10px 10px 10px 10px !important;
                            }
                            .MuiAutocomplete-root {
                                margin: 0px 0px 0px 0px !important;
                            }
                        }
                    }
                }
            }
        }

    }
}

.yoti-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.digital-id-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .digital-id-message {
        font-family: Lato-Bold;
        color: #2D7FF9;
        font-size: 16px;
        font-weight: 100;
        min-height: 26px;
        margin: 10px 0 10px;
        word-wrap: break-word;
        width: 100%;
        padding: 10px 30px 10px 30px;
    }
    .digital-id-btn {
        width: 100%;
    }
}


.modal-content-height {
    max-height: 250px !important;
}