.modal-container {
    overflow: hidden;
    .modal-inner {
      width: 100%;
      height: 80vh;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(235, 237, 244);
      box-shadow: 8px 8px 16px 0px rgba(74, 80, 82, 0.4);
      border-radius: 25px!important;
      padding: 0px 20px 20px;
      margin: 50px auto 0;
      outline: none;
      overflow-y: hidden;
      overflow-x: hidden;
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translate(-50%);
      padding: 17px!important;  
      .section-header {
        position: sticky;
        top: 0px;
        z-index: 3;
        background: #fff;
        // padding: 25px 0px;
        .section-name {
          height: 100%;
          color: rgb(49, 49, 49);
          font-size: 21px;
          font-family: Montserrat-Semibold;
          font-weight: 600;
          letter-spacing: 0px;
          position: relative;
          .heading-underline {
            width: 24px;
            height: 2px;
            background: #042D76;
            border-radius: 1px;
            margin-top: 12px;
          }
        }
        .close-icon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          cursor: pointer;
        }
      }
      .modal-content {
        width: auto;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 8px;
        }
        
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px transparent;
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: rgba(21, 53, 73, 0.2);
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(21, 53, 73, 0.2);
        }
        .modal-card {
          width: auto;
        }
      }
    }
  }