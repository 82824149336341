.modal-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    .modal-header-title-container {
        padding: 10px;
        display: flex;
        align-items: center;
        .modal-header-title {
            color: rgb(49, 49, 49);
            font-size: 21px;
            font-weight: 600;
            letter-spacing: 0px;
        }
    }
    .modal-header-action {
        margin-left: auto;
        img {
            cursor: pointer;
        }
    }
}
  
  
