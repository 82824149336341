.rating-wrapper {
    text-align: center;
    .rating-stars{
      border-radius: 5px;
      border: 1px solid rgb(221, 224, 233);
      height: 54px;
      padding-top: 9px;
    }
    .sub-title {
      color: rgb(49, 49, 49);
      font-family: Montserrat-Semibold;
      font-size: 14px;
      font-weight: 600;
      height: 14px;
      letter-spacing: 0px;
      text-align: center;
      margin-bottom: 12px;
    }
    .feedback-image {
      margin-top: 10px;
      .img-smiley {
        height: 52px;
        width: 52px;
      }
    }
    .feedback-text {
      .MuiOutlinedInput-multiline {
        height: 110px !important;
        .MuiOutlinedInput-inputMultiline {
              height: 100px !important;
              overflow: unset !important;
          }
      }
    }
  }
  