.card-element {
    height: 44px !important;
    margin-top: 0px !important;
    border: 1px solid rgba(0, 0, 0, 0.87);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 16px;

    &:hover {
        outline: none;
        border: 2px solid #2D7FF9;
    }
}

.fullWidth {
    width: 100%;
    margin: 20px 30px 0px 0px;
    text-align: center;
}

.payment-status-check-modal {
    //display: flex;
    justify-content: center; 
    width: 100%;   
}