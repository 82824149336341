#roleDetails-wrapper {
    width: 60%;
    .role {
        background: rgba(140, 202, 240, 0.2);
        mix-blend-mode: multiply;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 18px;
        margin: 22px 40px 22px 0px;
        padding: 0px 30px 30px 10px;
        display: grid;
        &-header {
            margin: 20px 5px 15px 20px;
            display: flex;
            float: left;
            &-location {
                margin: 0px 20px 0px 10px;
                font-family: Montserrat-SemiBold;
                font-weight: bold;
                font-size: 18px;
                justify-content: left;
                color: #042D76;
            }
        }
        &-processes {
            float: left;
            margin-left: 20px;
            .compliance-points {
                margin: 14px 15px 0px 0px;
                width: 100%;
                background: #F08383;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                border-radius: 13px;
                height: 100px;
                cursor: pointer;
                justify-content: center;
                .compliance-point-icon {
                    margin: 30px 0px 0px 30px;
                    float: left;
                    vertical-align: top;
                    img{
                        width: 40px;
                        height: 30px;
                    }
                }
                .compliance-point-text {
                    margin: 36px 5px 0px 25px;
                    //height: 75px;
                    float: left;
                    justify-content: center;
                    font-family: Montserrat-Semibold;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 20px;
                    display: flex;
                    text-align: center;
                    vertical-align: center;
                    align-items: center;
                    text-align: center;
                    color: rgba(250, 250, 252, 1);
                }
                .compliance-point-status {
                    margin: 36px 25px 0px 25px;
                    float: right;
                    font-family: Montserrat-SemiBold;
                    line-height: 20px;
                    font-weight: bold;
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.95);
                }
            }
        }
    }
}